<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <el-card class="box-card-title">
        <div class="box-card-title-item">
          <span>积分商城</span>
          <div>
            <span
              v-text="
                userScore[0] ? `拥有积分:${userScore[0].number}` : '拥有积分:0'
              "
              >拥有积分</span
            >
            <span @click="alterScore">兑换记录</span>
          </div>
        </div>
      </el-card>
      <el-card class="box-card-box">
        <div class="goodlist-list" v-if="scorecardList[0]">
          <div
            class="goodlist-list-box"
            v-for="(item, index8) in scorecardList"
            :key="index8"
          >
            <div class="goodlist-list-box-box">
              <div class="goodlist-include-box">
                <img class="goodlist-list-img" :src="item.productImg" alt="" />
              </div>
              <p class="goodlist-list-txt">{{ item.productName }}</p>
              <div class="price-row">
                <div>
                  <span class="price-a">积分:</span>
                  <span class="price-b">{{ item.needScore }}</span>
                </div>
                <div>
                  <span
                    :style="{ color: item.state == 1 ? '#eb723c' : '#999' }"
                    v-text="item.state == 1 ? '可兑换' : '售罄'"
                  ></span>
                </div>
              </div>
              <div class="goodlist-bottom">
                <div class="goodlist-bottom-left">
                  <span>兑换</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 兑换记录 -->
      <div class="Forrecord" v-if="Forrecordactive">
        <el-card class="box-card" v-if="ForrecordList[0]">
          <div slot="header" class="clearfix">
            <span>兑换记录</span>
            <el-button
              icon="el-icon-circle-close"
              style="float: right; padding: 0px 0; font-size: 22px"
              type="text"
              @click="Forrecordactive = false"
            ></el-button>
          </div>
          <div v-for="(v, k) of ForrecordList" :key="k" class="box-card-list">
            <span
              :style="{ lineHeight: '40px' }"
              v-text="`电话:${v.phone}`"
            ></span>
            <span
              :style="{ lineHeight: '40px' }"
              v-text="v.state == 1 ? '已兑换' : '已发货'"
            ></span>
            <el-popover placement="right" width="300" trigger="click">
              <div>
                <span :style="{ marginLeft: '16px' }"> 用户Id: </span>
                <span>{{ v.integra1Ma11Id }}</span>
              </div>
              <div>
                <span :style="{ marginLeft: '16px' }">商城Id:</span>
                <span>{{ v.userId }}</span>
              </div>
              <div>
                <span :style="{ marginLeft: '29px' }">电话:</span>
                <span>{{ v.phone }}</span>
              </div>
              <div>
                <span>发货地址:</span>
                <span>{{ v.address }}</span>
              </div>
              <div>
                <span>兑换状态:</span>
                <span>{{ v.state == 1 ? "已兑换" : "已发货" }}</span>
              </div>
              <el-button slot="reference">查看详情</el-button>
            </el-popover>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 商城页数
      pageNum: 1,
      //   用户积分
      userScore: [],
      //  商品列表
      scorecardList: [],
      // 兑换记录列表
      ForrecordList: [],
      //   切换兑换记录显示状态
      Forrecordactive: false,
    };
  },
  methods: {
    // 获取积分商城信息
    async getScorecard() {
      const res = await this.$http({
        method: "get",
        url: `front/integralMall/findAllIntegralMall?pageNum=${this.pageNum}&pageSize=10`,
      });
      if (res.data.data) {
        this.scorecardList = res.data.data.list;
        console.log(this.scorecardList);
      }
    },
    // 获取用户积分
    async getuserScore() {
      const res = await this.$http({
        method: "get",
        url: `front/integral/findAllIntegral?userId=${sessionStorage.getItem(
          "userId"
        )}`,
      });
      console.log(res.data.data.list);
      this.userScore = res.data.data.list;
    },
    // 查询兑换记录
    async alterScore() {
      const res = await this.$http({
        method: "get",
        url: `front/cdkLog/findAllCdkLog?userId=${sessionStorage.getItem(
          "userId"
        )}`,
      });
      this.ForrecordList = res.data.data.list;

      console.log(this.ForrecordList);
      if (this.ForrecordList[0]) {
        this.Forrecordactive = true;
      } else {
        this.$message.error("没有兑换记录");
      }
    },
  },
  created() {
    this.getScorecard();
    this.getuserScore();
  },
};
</script>
<style lang="scss" scoped>
.box {
  background-color: #f5f5f5;
  height: 100vh;
  overflow: hidden;
  .el-card {
    min-width: 1100px;
  }
}
.box-card-title {
  width: 65%;
  margin: 0 auto;
  margin-top: 180px;
}
.box-card-box {
  width: 65%;
  height: 500px;
  margin: 0 auto;
}
.goodlist-list {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  width: 77%;
  min-width: 800px;
  /* height: 1500px; */
  margin-top: 20px;
}
.goodlist-list-box {
  width: 25%;
  min-width: 200px;
  /* height: 340px; */
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  /* height: 350px; */
}
.goodlist-include-box {
  width: 100%;
  height: 120px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 140px;
  border-radius: 5px;
  /* margin-left: 22%;
    margin-top: 12%; */
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.price-row {
  margin-top: 12px;
  padding: 0 6px;
  display: flex;
  justify-content: space-between;
}
.price-row div:nth-child(1) span {
  color: #e4393c;
  font-size: 16px;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 40px;
  margin-top: 15px;
  border-radius: 6px;
}
.goodlist-bottom-left {
  width: 1000%;
  height: 100%;
  background: #df3033;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
}
.goodlist-bottom-left:hover {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.goodlist-bottom-left span {
  color: #fff;
}
.goodlist-bottom-left span {
  line-height: 40px;
}
.box-card-title-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.box-card-title-item > div {
  width: 200px;
  display: flex;
  justify-content: space-around;
}
.box-card-title-item > div > span {
  color: #409eff;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
}
/* 兑换记录 */
.Forrecord {
  width: 400px;
  height: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -150px;
}
.Forrecord .box-card {
  width: 100%;
  height: 100%;
}
.Forrecord .box-card-list {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.Forrecord .box-card-list span {
  color: #666;
}
.Forrecord .box-card-list span:nth-child(3) {
  color: #409eff;
}
</style>